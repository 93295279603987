import { CSS } from "@stitches/react"
import { globalCss, theme } from "@theme/stitches.config"
import { normalize } from "stitches-normalize-css"
import "@fontsource/source-sans-pro/300.css"
import "@fontsource/source-sans-pro/400.css"
import "@fontsource/source-sans-pro/600.css"
import "@fontsource/source-sans-pro/700.css"
import "swiper/css/bundle"

const customGlobalStyles: Record<string, CSS> = {
    "*, *::before, *::after": {
        boxSizing: "border-box"
    },
    html: {
        textSizeAdjust: "100%",
        fontFamily: theme.fonts.body
    },
    body: {
        fontFamily: theme.fonts.body,
        fontWeight: theme.fontWeights.regular,
        backgroundColor: theme.colors.N0,
        fontSize: "1rem",
        lineHeight: 1,
        margin: 0
    },
    "h1, .h1": {
        lineHeight: 1.15,
        fontSize: theme.fontSizes.h1Mobile,
        letterSpacing: "-0.5px",
        lineHeight: "62px",
        fontFamily: theme.fonts.heading,
        fontSize: theme.fontSizes.h1Mobile,
        fontWeight: theme.fontWeights.semiBold,
        margin: 0,
        my: 24,
        "@mdUp": {
            fontSize: theme.fontSizes.h1
        }
    },
    "h2, .h2": {
        lineHeight: 1.15,
        fontFamily: theme.fonts.heading,
        fontSize: theme.fontSizes.h2Mobile,
        fontWeight: theme.fontWeights.semiBold,
        margin: 0,
        my: 22,
        "@mdUp": {
            fontSize: theme.fontSizes.h2
        }
    },
    "h3, .h3": {
        lineHeight: 1.1,
        fontFamily: theme.fonts.heading,
        fontSize: theme.fontSizes.h3Mobile,
        "@mdUp": {
            fontSize: theme.fontSizes.h3
        }
    },
    "h4, .h4": {
        lineHeight: 1.25,
        fontFamily: theme.fonts.heading,
        fontSize: theme.fontSizes.h4Mobile,
        "@mdUp": {
            fontSize: theme.fontSizes.h4
        }
    },
    "h5, .h5": {
        lineHeight: 1.25,
        fontFamily: theme.fonts.heading,
        letterSpacing: 2,
        fontSize: theme.fontSizes.h5Mobile,
        "@mdUp": {
            fontSize: theme.fontSizes.h5
        }
    },
    "h1,h2,h3,h4,h5,.h1,.h2,.h3,.h4,.h5": {
        wordBreak: "break-word",
        color: theme.colors.N1000,
        fontFamily: theme.fonts.heading,
        margin: 0,
        marginBottom: "1.5rem"
    },
    "h1,h2,h3,.h1,.h2,.h3": {
        fontWeight: theme.fontWeights.bold
    },
    "h4,h5,.h4,.h5": {
        fontWeight: theme.fontWeights.semiBold
    },
    ".gatsby-focus-wrapper": {
        overflow: "hidden"
    },
    h1: {
        fontFamily: theme.fonts.heading,
        fontSize: theme.fontSizes.h1Mobile,
        fontWeight: theme.fontWeights.semiBold,
        lineHeight: "62px",
        margin: 0,
        my: 24,
        "@mdUp": {
            fontSize: theme.fontSizes.h1
        }
    },
    h2: {
        fontFamily: theme.fonts.heading,
        fontSize: theme.fontSizes.h2Mobile,
        fontWeight: theme.fontWeights.semiBold,
        lineHeight: "62px",
        margin: 0,
        my: 22,
        "@mdUp": {
            fontSize: theme.fontSizes.h2
        }
    },
    h3: {
        fontFamily: theme.fonts.heading,
        fontSize: theme.fontSizes.h3Mobile,
        fontWeight: theme.fontWeights.semiBold,
        lineHeight: "38px",
        margin: 0,
        my: 20,
        "@mdUp": {
            fontSize: theme.fontSizes.h3
        }
    },
    p: {
        fontFamily: theme.fonts.body,
        fontSize: theme.fontSizes.mobileBody,
        lineHeight: "24px",
        fontWeight: theme.fontWeights.regular,
        margin: 0,
        "@mdUp": {
            fontSize: theme.fontSizes.desktopBody
        }
    },
    a: {
        textDecoration: "none",
        color: "inherit"
    },
    strong: {
        fontWeight: theme.fontWeights.bold
    },
    ".btn": {
        backgroundColor: theme.colors.N1000,
        textTransform: "uppercase",
        color: theme.colors.N0,
        height: 40,
        my: 10,
        appearance: "none",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: 0,
        borderRadius: 20,
        padding: "10px 20px",
        fontWeight: theme.fontWeights.bold,
        letterSpacing: 1,
        fontSizePxToRem: 12,
        width: "fit-content",
        textDecoration: "none",
        fontFamily: theme.fonts.body,
        lineHeight: "24px",
        transition: "all 200ms ease",
        outline: "none"
    },
    ".btn.btn-outlined": {
        backgroundColor: "transparent",
        border: `1px solid ${theme.colors.N1000}`,
        color: theme.colors.N1000,
        "&:hover": {
            backgroundColor: theme.colors.N1000,
            color: theme.colors.N0
        }
    },
    ".btn.btn-filled-yellow": {
        backgroundColor: theme.colors.proofYellow,
        color: theme.colors.N1000,
        "&:hover": {
            backgroundColor: theme.colors.proofGrey,
            color: theme.colors.N1000
        }
    },
    ".btn.btn-filled-black": {
        backgroundColor: theme.colors.N1000,
        color: theme.colors.N0,
        "&:hover": {
            backgroundColor: "#333",
            color: theme.colors.N0
        }
    },
    ".btn.btn-filled-purple": {
        backgroundColor: theme.colors.proofPurple,
        color: theme.colors.N0,
        "&:hover": {
            backgroundColor: "#333",
            color: theme.colors.N0
        }
    },
    ".btn.btn-filled-white": {
        backgroundColor: theme.colors.N0,
        color: theme.colors.N1000,
        "&:hover": {
            backgroundColor: "#333",
            color: theme.colors.N0
        }
    },
    ".blockquote, p.blockquote": {
        fontSizePxToRem: 28,
        fontWeight: theme.fontWeights.semiBold,
        lineHeight: 1.1,
        mt: 0,
        mb: 0,
        mx: 40,
        my: 10
    }
}

export const globalStyles = globalCss(...normalize, customGlobalStyles)
